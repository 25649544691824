/* Buttons */

@import "helpers/mixin";
@import "helpers/fonts";
@import "helpers/icons";
@import "helpers/settings";

.button{
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    height: 40px;
    .fontHNRegular(15px, 20px);
    border-radius: 3px;
    background: @cGreen;
    padding: 11px 15px 9px;
    border: 0;
    color: @cWhite;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .transition(~"background .15s ease, box-shadow .15s ease");
    &__loading{
        display: none;
    }
    &._loading{
        background: @cGray;
        border-color: @cGray;
        color: @cBlack2;
        font-size: 0;
        pointer-events: none;
        padding-left: 28px;
        padding-right: 28px;
        .no-touchevents &:hover{
            border-color: @cGray;
        }
        .button__loading{
            display: inline;
            font-size: 15px;
        }
    }
    &._waiting{
        background: @cGrayD3;
        border-color: @cGrayD3;
        color: @cGrayD3;
        /*font-size: 0;*/
        pointer-events: none;
        padding-left: 28px;
        padding-right: 28px;
        position: relative;
        min-width: 80px;
        &:before{
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            .icon-loader-small(30px, 30px);
            margin: -15px 0 0 -15px;
            .animate(rotation, 2s, infinite, 0s, linear);
        }
    }
    .no-touchevents &:hover{
        box-shadow: 0 6px 20px @opGreen54;
    }
    &:disabled{
        background: @cGrayD3;
        pointer-events: none;
    }
    &--free{
        width: auto;
        padding-left: 28px;
        padding-right: 28px;
    }
    &--black{
        .fontHNRegular(15px, 20px);
        height: 40px;
        padding: 11px 15px 9px;
        color: @cButtonBlackColor;
        background: @cButtonBlackBG;
        .no-touchevents &:hover{
            padding: 11px 15px 9px;
            box-shadow: 0 5px 20px @opShadow33;
        }
    }
    &--red{
        .fontHNRegular(15px, 20px);
        height: 40px;
        padding: 11px 15px 9px;
        color: @cWhite;
        background: @cRed;
        .no-touchevents &:hover{
            padding: 11px 15px 9px;
            box-shadow: 0 6px 20px @opRed54;
        }
    }
    &--notify{
        .inline-block();
        width: auto;
        background: transparent;
        color: @cWhite;
        border: 1px solid @opWhite30;
        height: 30px;
        border-radius: 3px;
        padding: 5px 14px 5px;
        min-width: 80px;
        box-shadow: none !important;
        .fontHNRegular(13px, 19px);
        .transition(~"border-color .15s ease");
        ._ios &{
            padding: 6px 14px 4px;
        }
        .no-touchevents &:hover{
            background: transparent;
            color: @cWhite;
            border-color: @cWhite;
        }
    }
    &--small-red{
        background: @cRed;
        .no-touchevents &:hover{
            box-shadow: 0 6px 20px @opRed54;
        }
    }
}

.btn-bord{
    .inline-block();
    box-sizing: border-box;
    text-decoration: none;
    height: 30px;
    .fontHNRegular(13px, 19px);
    border-radius: 3px;
    border: @cGray solid 1px;
    padding: 5px 15px 4px;
    color: @cBlack2;
    background: transparent;
    .transition(~"border-color .15s ease");
    white-space: nowrap;
    text-align: center;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    .no-touchevents &:hover{
        border-color: @cGreen;
        padding: 4px 14px 3px;
        border-width: 2px;
    }
    ._ios &{
        padding: 6px 15px 3px;
    }
    .no-touchevents._ios &:hover{
        padding: 5px 14px 2px;
    }
    &._active{
        color: @cWhite;
        border-color: @cGreen;
        background: @cGreen;
    }
    &--purchases{
        display: block;
        margin-top: 14px;
        width: 100%;
        @media screen and (min-width: 700px) {
            position: absolute;
            width: auto;
            right: 0;
            bottom: -4px;
            padding: 5px 12px 4px;
            .no-touchevents &:hover{
                padding: 4px 11px 3px !important;
            }
            ._ios &{
                padding: 6px 12px 3px;
            }
            .no-touchevents._ios &:hover{
                padding: 5px 11px 2px !important;
            }
        }
        @media screen and (min-width: 1340px) {
            position: relative;
            bottom: 0;
            top: -7px;
            margin: 0;
            display: inline-block;
            font-size: 0px !important;
            max-width: 38px;
            .transition(~"max-width .3s ease");
            span{
                display: inline-block;
                overflow: hidden;
                width: 0%;
                .transition(~"width .3s ease");
                font-size: 13px !important;
            }
            &:after{
                content: '...';
                position: relative;
                top: -4px;
                font-size: 13px !important;
            }
            .no-touchevents .purchases-table__wrap:hover &{
                max-width: 100%;
                span{
                    width: 100%;
                    text-overflow: ellipsis;
                }
                &:after{
                    display: none;
                }
            }
        }
    }
    &--transparent{
        border-color: @cGray64;
        color: @cWhite;
        background: transparent;
    }
    &--thin{
        padding: 5px 11px 4px;
        .no-touchevents &:hover{
            padding: 4px 10px 3px;
        }
        ._ios &{
            padding: 6px 11px 3px;
        }
        .no-touchevents._ios &:hover{
            padding: 5px 10px 2px;
        }
    }
    &--more{
        width: 100%;
        height: 50px;
        text-align: center;
        background: transparent;
        text-transform: uppercase;
        border-radius: 3px;
        .fontHNMedium(14px, 24px);
        padding-top: 13px;
        letter-spacing: 1px;
        overflow-anchor: none;
        @media screen and (min-width: 1024px){
            height: 40px;
            padding-top: 8px;
        }
        .no-touchevents &:hover{
            padding-top: 12px;
            @media screen and (min-width: 1024px){
                padding-top: 7px;
            }
        }
        ._ios &{
            padding-top: 14px;
            @media screen and (min-width: 1024px){
                padding-top: 9px;
            }
        }
        .no-touchevents._ios &:hover{
            padding-top: 13px;
            @media screen and (min-width: 1024px){
                padding-top: 8px;
            }
        }
    }
    &--follow{
        width: 30px;
        padding: 5px 7px 4px 7px;
        position: absolute;
        top: -2px;
        right: 0;
        margin: 0;
        border-color: @cGray;
        .transition(~"border-color .15s ease, background .15s ease, color .15s ease");
        @media screen and (max-width: 767px){
            font-size: 0 !important;
        }
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 6px;
            left: 6px;
            .icon-follow-green(16px, 16px);
            z-index: 1;
            opacity: 0;
            .transition(~"opacity .15s ease");
        }
        &:after{
            content: '';
            display: block;
            position: absolute;
            top: 6px;
            left: 6px;
            .icon-follow-black(16px, 16px);
            z-index: 2;
            opacity: 1;
            .transition(~"opacity .15s ease");
        }
        .no-touchevents &:hover{
            padding: 4px 6px 3px 6px;
            &:before{
                top: 5px;
                left: 5px;
                opacity: 1;
            }
            &:after{
                top: 5px;
                left: 5px;
                opacity: 0;
            }
        }
        ._ios &{
            padding: 6px 7px 3px 7px;
        }
        .no-touchevents._ios &:hover{
            padding: 5px 6px 2px 6px;
        }
        &._active{
            color: @cWhite;
            background: @cGreen;
            border-color: @cGreen;
            &:before{
                opacity: 0;
            }
            &:after{
                opacity: 1;
                .icon-follow(16px, 16px);
            }
            ._over{
                display: none;
            }
            .no-touchevents &:hover{
                ._base{
                    display: none;
                }
                ._over{
                    display: inline;
                }
                background: transparent;
                color: @cBlack2;
                &:before{
                    opacity: 1;
                }
                &:after{
                    opacity: 0;
                }
            }
        }
        @media screen and (min-width: 700px){
            position: relative;
            top: 0;
            margin: -8px 0 0 9px;
            flex-shrink: 0;
        }
        @media screen and (min-width: 768px){
            padding: 5px 7px 4px 27px;
            position: relative;
            top: 0;
            margin: -8px 0 0 9px;
            flex-shrink: 0;
            width: auto;
            .no-touchevents &:hover{
                padding: 4px 6px 3px 26px;
                &:before{
                    top: 5px;
                    left: 5px;
                }
                &:after{
                    top: 5px;
                    left: 5px;
                }
            }
            ._ios &{
                padding: 6px 7px 3px 27px;
            }
            .no-touchevents._ios &:hover{
                padding: 5px 6px 2px 26px;
            }
            &--no-ico{
                padding-left: 7px;
                &:before{
                    content: none;
                }
                &:after{
                    content: none;
                }
                .no-touchevents &:hover{
                    padding-left: 6px;
                }
            }
            &._active{
                background: @cGreen;
                border-color: @cGreen;
            }
        }
        @media screen and (min-width: 1024px){
            &--white{
                color: @cWhite;
                border-color: @opWhite30;
                &:after{
                    .icon-follow(16px, 16px);
                }
            }
        }
    }
    &--cookies{
        font: normal 400 15px/20px 'Neue Helvetica Paneuropean 55 Roman', sans-serif /* rtl: normal 400 15px/20px Arial, sans-serif */;
    }
    &--arrow{
        margin-right: 11px;
        max-width: calc(~"100% - 11px");
        border-right: 0;
        position: relative;
        overflow: visible;
        padding: 5px 10px 4px;
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: -1px;
            right: -10px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 0 15px 11px;
            border-color: transparent transparent transparent @cGray;
            z-index: 1;
            .transition(~"border-color .15s ease");
        }
        &:after{
            content: '';
            display: block;
            position: absolute;
            top: 1px;
            right: -9px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 13px 0 13px 10px;
            border-color: transparent transparent transparent @cGrayF2;
            z-index: 2;
            .transition(~"border-color .15s ease");
        }
        &--gray:after{
            border-color: transparent transparent transparent @cGrayE0;
        }
        .no-touchevents &:hover{
            padding: 4px 10px 3px 9px;
            &:before{
                top: -2px;
                border-color: transparent transparent transparent @cGreen;
            }
            &:after{
                border-width: 12px 0 12px 9px;
                top: 1px;
                right: -8px;
            }
        }
        ._ios &{
            padding: 6px 10px 3px;
        }
        .no-touchevents._ios &:hover{
            padding: 5px 10px 2px 9px;
        }
    }
    &__text{
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(~"100% + 2px");
    }
    &:disabled{
        pointer-events: none;
        opacity: 0.3;
    }
}

._success{
    .inline-block();
    .icon-success-small(20px, 20px);
    margin: -4px -25px 0 5px;
}

.text-button{
    background: transparent;
    text-decoration: none;
    height: 40px;
    color: @cGreen;
    .fontHNRegular(15px, 22px);
    padding: 0;
    border-radius: 0;
    padding: 9px 0 9px;
    border: 0;
    @media screen and (min-width: 700px){
        padding: 10px 0 8px;
    }
}

.text-button-reset{
    .inline-block(top);
    color: @cGreen;
    .fontHNRegular(13px, 19px);
    text-decoration: none;
    &._disabled{
        opacity: 0.3;
        pointer-events: none;
    }
}
/* /Buttons */


/* Dropdown */
.dropdown{
    display: block;
    position: relative;
    &--date{
        border-right: @cGrayE0 solid 1px;
        border-left: @cGrayE0 solid 1px;
        .inline-block(top);
        width: 139px;
        margin-bottom: 1px !important;
    }
    &--date + &--date{
        border-left: none;
        width: 104px;
    }
    &--sign-in{
        width: 100%;
    }
    &--table{
        margin: 0 0 0 -10px;
        width: 16px;
        @media screen and (min-width: 700px){
            margin-left: -26px;
        }
    }
    &--130{
        @media screen and (min-width: 768px){
            width: 130px;
        }
    }
    &__helper{
        position: relative;
        &:before{
            content: '';
            display: block;
            z-index: 1;
            position: absolute;
            top: 50%;
            right: 8px;
            .icon-dropdownGray(12px, 12px);
            margin: -6px 0 0;
            opacity: 1;
            .transition(~"opacity .15s ease");
            pointer-events: none;
        }
        &:after{
            content: '';
            display: block;
            z-index: 2;
            position: absolute;
            top: 50%;
            right: 8px;
            .icon-dropdownGreen(12px, 12px);
            margin: -6px 0 0;
            opacity: 0;
            .transition(~"opacity .15s ease");
            pointer-events: none;
        }
        &._opened{
            &:before{
                transform: scale(-1);
            }
            &:after{
                transform: scale(-1);
            }
        }
    }
    &__head{
        display: block;
        width: 100%;
        background: transparent;
        border-radius: 3px;
        border: 1px solid @cGray;
        color: @cBlack2;
        .fontHNRegular(13px, 19px);
        text-align: left;
        position: relative;
        box-sizing: border-box;
        padding: 10px 25px 10px 10px;
        height: 40px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .transition(~"border-color .15s ease, color .15s ease, background-color .15s ease");
        .no-touchevents &:hover, &:focus-within{
            border-width: 2px;
            border-color: @cGreen;
            padding: 9px 24px 9px 9px;
            &:before{
                margin-right: -1px;
            }
            &:after{
                margin-right: -1px;
            }
        }
        &--big-text, &--sign-in{
            .fontHNRegular(15px, 20px);
        }
        &--sign-in{
            background: @cWhite;
        }
        &._opened{
            border-width: 2px;
            border-color: @cGreen;
            padding: 9px 24px 9px 9px;
            &:before{
                margin-right: -1px;
            }
            &:after{
                margin-right: -1px;
            }
        }
        &--small{
            height: 30px;
            padding: 5px 25px 5px 10px;
            .no-touchevents &:hover, &:focus-within{
                border-width: 2px;
                border-color: @cGreen;
                padding: 4px 24px 4px 9px;
            }
            &._opened{
                border-width: 2px;
                border-color: @cGreen;
                padding: 4px 24px 4px 9px;
            }
        }
        &--filter{
            height: 36px;
            padding: 8px 25px 8px 10px;
            .no-touchevents &:hover{
                padding: 7px 24px 7px 9px;
            }
        }
        &--desksmall{
            @media screen and (min-width: 700px){
                height: 30px;
                padding: 5px 25px 5px 10px;
                .no-touchevents &:hover, &:focus-within{
                    border-width: 2px;
                    border-color: @cGreen;
                    padding: 4px 24px 4px 9px;
                }
            }
        }
        @media screen and (min-width: 768px){
            height: 30px;
            padding-top: 5px;
            padding-bottom: 5px;
            .no-touchevents &:hover{
                padding-top: 4px;
                padding-bottom: 4px;
            }
            &--big, &--sign-in{
                height: 40px;
                padding-top: 10px;
                padding-bottom: 10px;
                .no-touchevents &:hover{
                    padding-top: 9px;
                    padding-bottom: 9px;
                }
            }
            &--filter{
                height: 36px;
                padding: 8px 25px 8px 10px;
                .no-touchevents &:hover{
                    padding: 7px 24px 7px 9px;
                }
            }
            &--40{
                height: 40px;
                .fontHNRegular(15px, 20px);
                padding-top: 10px;
                padding-bottom: 10px;
                .no-touchevents &:hover{
                    padding-top: 9px;
                    padding-bottom: 9px;
                }
            }
        }
        &--date{
            border-radius: 0;
            border-color: @cWhite;
            height: 47px;
            .fontHNBold(13px, 19px);
            @media screen and (min-width: 768px){
                height: 47px;
            }
        }
        ._tablet{
            display: none;
            @media screen and (min-width: 768px){
                display: inline;
            }
        }
        &._placeholder, &--not-selected, &::placeholder{
            color: @opBlack2_30;
        }
        &:before{
            content: '';
            display: block;
            z-index: 1;
            position: absolute;
            top: 50%;
            right: 8px;
            .icon-dropdownGray(12px, 12px);
            margin: -6px 0 0;
            opacity: 1;
            .transition(~"opacity .15s ease");
        }
        &:after{
            content: '';
            display: block;
            z-index: 2;
            position: absolute;
            top: 50%;
            right: 8px;
            .icon-dropdownGreen(12px, 12px);
            margin: -6px 0 0;
            opacity: 0;
            .transition(~"opacity .15s ease");
        }
        &._opened{
            &:before{
                transform: scale(-1);
            }
            &:after{
                transform: scale(-1);
            }
        }
        &--green{
            &:after{
                .icon-dropdownWhite(12px, 12px);
                transform: scale(1);
            }
            .no-touchevents &:hover, &:focus-within, &._opened{
                background: @cGreen;
                color: @cWhite;
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
        &--share{
          height: 30px;
          padding: 5px 8px 5px 27px;
          .no-touchevents &:hover, &:focus-within, &._opened{
              border-width: 2px;
              border-color: @cGreen;
              background: @cGreen;
              color: @cWhite;
              padding: 4px 7px 4px 26px;
              &:before{
                  left: 5px;
                  opacity: 0;
              }
              &:after{
                  left: 5px;
                  opacity: 1;
              }
          }
          &:before{
              right: auto;
              left: 6px;
              .icon-share(16px, 16px);
              margin: -8px 0 0;
          }
          &:after{
              right: auto;
              left: 6px;
              .icon-share-over(16px, 16px);
              margin: -8px 0 0;
          }
        }
        &._required ._star{
            display: block;
        }
        &._required._error ._star{
            &:before{
                opacity: 0;
            }
            &:after{
                opacity: 1;
            }
        }
        &._required{
            padding-left: 29px;
            position: relative;
            .no-touchevents &:hover, &:focus, &._opened{
                padding-left: 28px;
                ._star{
                    margin: -1px;
                }
            }
        }
        &--add-to-list{
            color: @cWhite;
            background: @opShadow40;
            .fontHNRegular(14px, 21px);
            text-align: center;
            border: 0;
            border-radius: 0;
            &:after{
                .icon-dropdownWhite(12px, 12px);
                transform: scale(1);
            }
            .no-touchevents &:hover, &:focus-within, &._opened{
                background: @cGreen;
                color: @cWhite;
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }
    &__only-arrow{
        display: block;
        margin: 0;
        width: 16px;
        height: 18px;
        position: relative;
        cursor: pointer;
        &:before{
            content: '';
            display: block;
            z-index: 1;
            position: absolute;
            top: 50%;
            right: 8px;
            .icon-dropdownGray(12px, 12px);
            margin: -6px;
            opacity: 1;
            .transition(~"opacity .15s ease");
        }
        &:after{
            content: '';
            display: block;
            z-index: 2;
            position: absolute;
            top: 50%;
            right: 8px;
            .icon-dropdownGreen(12px, 12px);
            margin: -6px;
            opacity: 0;
            .transition(~"opacity .15s ease");
        }
        &._opened{
            &:before{
                transform: scale(-1);
            }
            &:after{
                transform: scale(-1);
            }
        }
        .no-touchevents &:hover{
            &:before{
                opacity: 0;
            }
            &:after{
                opacity: 1;
            }
        }
    }
    &-body{
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 140px;
        //max-height: 200px;
        background: @cWhite;
        border-radius: 8px;
        z-index: 111;
        box-shadow: 0 8px 22px @opShadow20;
        overflow: hidden;
        &--right{
            right: 0;
            left: auto;
            min-width: 149px;
        }
        &--big{
            width: 300px;
            max-width: calc(100vw - 60px);
            min-width: 260px;
        }
        &--to-list{
            @media screen and (min-width: 700px){
                right: 0;
                left: auto;
                min-width: 272px;
            }
            @media screen and (min-width: 1024px){
                right: auto;
                left: 0;
            }
        }
        &._opened{
            display: block;
        }
    }
    &-item{
        border-top: 1px solid @cGrayF2;
        text-align: left;
        &:first-child{
            border-top: 0;
        }
    }
    &__item{
        display: block;
        color: @cBlue;
        .fontHNRegular(15px, 20px);
        padding: 14px 15px 11px 15px;
        box-sizing: border-box;
        text-decoration: none;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .transition(~"background .15s ease, color .15s ease");
        .no-touchevents &:hover{
            color: @cWhite;
            background: @cGreen;
            .dropdown__ico{
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                }
            }
        }
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 5px;
            background: @cGreen;
            opacity: 0;
            .transition(~"opacity .15s ease");
        }
        &._active{
            &:before{
                opacity: 1;
            }
        }
        &._checked{
            padding-right: 42px;
            &:before{
                left: auto;
                bottom: auto;
                top: 14px;
                right: 15px;
                opacity: 1;
                .transition(~"opacity .15s ease");
                .icon-ddCheck(20px, 20px)
            }
            &:after{
                content: '';
                display: block;
                position: absolute;
                top: 14px;
                right: 15px;
                opacity: 0;
                .transition(~"opacity .15s ease");
                .icon-ddCheckOver(20px, 20px)
            }
            .no-touchevents &:hover{
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                }
            }
        }

        & .title {
            margin-right: 8px;
        }

        & .title--label {
            padding: 4px 8px 2px;
            font-size: 10px;
            vertical-align: middle;

            &.green {
                color: @cWhite;
                background: @cGreen;
            }

            &.orange {
                background-color: @cOrange1;
                color: @cWhite;
            }
        }
    }

    &--date &__item{
        .fontHNRegular(13px, 19px);
    }
    &__ico{
        .inline-block(top);
        margin: -13px 8px -13px -5px;
        height: 45px;
        width: 25px;
        box-sizing: border-box;
        position: relative;
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
            z-index: 1;
            .transition(~"opacity .15s ease");
        }
        &:after{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            z-index: 2;
            .transition(~"opacity .15s ease");
        }
        &--fb{
            &:before{
                .icon-fb-over(23px, 23px);
            }
            &:after{
                .icon-fb-white(23px, 23px);
            }
        }
        &--tw{
            &:before{
                .icon-tw-over(22px, 22px);
            }
            &:after{
                .icon-tw-white(23px, 23px);
            }
        }
        &--vk{
            &:before{
                .icon-vk-over(24px, 24px);
            }
            &:after{
                .icon-vk-white(23px, 23px);
            }
        }
        &--pi{
            &:before{
                .icon-pi-over(26px, 26px);
            }
            &:after{
                .icon-pi-white(23px, 23px);
            }
        }
        &--tu{
            &:before{
                .icon-tu-over(25px, 25px);
            }
            &:after{
                .icon-tu-white(23px, 23px);
            }
        }
        &--em{
            &:before{
                .icon-em-over(26px, 26px);
            }
            &:after{
                .icon-em-white(23px, 23px);
            }
        }
        &--cl{
            &:before{
                .icon-cl-over(26px, 26px);
            }
            &:after{
                .icon-cl-white(23px, 23px);
            }
        }
    }
}
.dropdown-black{
    display: block;
    position: relative;
    &__head{
        display: block;
        width: 100%;
        background: transparent;
        border-radius: 3px;
        border: 1px solid @cGray64;
        color: @cWhite;
        .fontHNRegular(13px, 19px);
        text-align: left;
        position: relative;
        box-sizing: border-box;
        padding: 5px 25px 5px 10px;
        height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .transition(~"border-color .15s ease");
        .no-touchevents &:hover{
            border-width: 2px;
            border-color: @cGreen;
            padding: 4px 24px 4px 9px;
            &:before{
                margin-right: -1px;
            }
            &:after{
                margin-right: -1px;
            }
        }
        &:before{
            content: '';
            display: block;
            z-index: 1;
            position: absolute;
            top: 50%;
            right: 8px;
            .icon-dropdownWhite(12px, 12px);
            margin: -6px 0 0;
            opacity: 1;
            .transition(~"opacity .15s ease");
        }
        &:after{
            content: '';
            display: block;
            z-index: 2;
            position: absolute;
            top: 50%;
            right: 8px;
            .icon-dropdownGreen(12px, 12px);
            margin: -6px 0 0;
            opacity: 0;
            .transition(~"opacity .15s ease");
        }
        &._opened{
            &:before{
                transform: scale(-1);
            }
            &:after{
                transform: scale(-1);
            }
        }
        @media screen and (min-width: 768px){
            height: 30px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
    &-body{
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 140px;
        background: @cWhite;
        border-radius: 8px;
        z-index: 111;
        &--right{
            right: 0;
            left: auto;
        }
        &--big{
            width: 300px;
        }
        &._opened{
            display: block;
        }
    }
    &-item{
        border-top: 1px solid @cGrayF2;
        &:first-child{
            border-top: 0;
        }
    }
    &__item{
        display: block;
        color: @cBlue;
        .fontHNRegular(13px, 19px);
        padding: 13px 15px 13px 15px;
        box-sizing: border-box;
        text-decoration: none;
        position: relative;
        .transition(~"background .15s ease, color .15s ease");
        .no-touchevents &:hover{
            color: @cWhite;
            background: @cGreen;
            .dropdown-black__ico{
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                }
            }
        }
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 5px;
            background: @cGreen;
            opacity: 0;
            .transition(~"opacity .15s ease");
        }
        &._active{
            &:before{
                opacity: 1;
            }
        }
    }
    &__ico{
        .inline-block(top);
        margin: -13px 8px -13px -5px;
        height: 45px;
        width: 25px;
        box-sizing: border-box;
        position: relative;
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
            z-index: 1;
            .transition(~"opacity .15s ease");
        }
        &:after{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            z-index: 2;
            .transition(~"opacity .15s ease");
        }
        &--fb{
            &:before{
                .icon-fb-over(23px, 23px);
            }
            &:after{
                .icon-fb-white(23px, 23px);
            }
        }
        &--tw{
            &:before{
                .icon-tw-over(22px, 22px);
            }
            &:after{
                .icon-tw-white(23px, 23px);
            }
        }
        &--vk{
            &:before{
                .icon-vk-over(24px, 24px);
            }
            &:after{
                .icon-vk-white(23px, 23px);
            }
        }
        &--pi{
            &:before{
                .icon-pi-over(26px, 26px);
            }
            &:after{
                .icon-pi-white(23px, 23px);
            }
        }
        &--tu{
            &:before{
                .icon-tu-over(25px, 25px);
            }
            &:after{
                .icon-tu-white(23px, 23px);
            }
        }
        &--em{
            &:before{
                .icon-em-over(26px, 26px);
            }
            &:after{
                .icon-em-white(23px, 23px);
            }
        }
        &--cl{
            &:before{
                .icon-cl-over(26px, 26px);
            }
            &:after{
                .icon-cl-white(23px, 23px);
            }
        }
    }
}
/* /Dropdown */

/* Radio button */
.radios{
    margin: 15px 0 0;
    &:first-child{
        margin-top: 0;
    }
    .radio-button{
        margin-top: 0;
        &:first-child{
            margin-top: 0;
        }
    }
}

.radio-button{
    display: block;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    .radios &{
        margin: 8px 0 0;
        &:first-child{
            margin-top: 0;
        }
    }
    .account-block-radios &{
        margin: 8px 0 0;
        &:first-child{
            margin-top: 0;
        }
    }
    &__input{
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        width: 16px;
        height: 16px;
        z-index: 1;
        visibility: visible;
        opacity: 0;
    }
    &__label{
        .inline-block(top);
        .fontHNRegular(15px, 22px);
        color: @cBlack2;
        box-sizing: border-box;
        padding: 0 0 0 24px;
        position: relative;
        cursor: pointer;
        max-width: 100%;
        .radios &{
            .fontHNRegular(13px, 19px);
        }
        &:after{
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            border: 1px solid @cGray;
            background-color: @cWhite;
            position: absolute;
            top: 2px;
            left: 0;
            border-radius: 50%;
            z-index: 1;
            .transition(~"border-width .15s ease, border-color .15s ease, background-color .15s ease");
            .radios &{
                top: 1px;
            }
        }
        .no-touchevents &:hover{
            &:after{
                border-width: 2px;
                border-color: @cGreen;
            }
        }
    }
    &__input:focus + &__label{
        &:after{
            border-width: 2px;
            border-color: @cGreen;
        }
    }
    &__input:checked + &__label{
        &:after{
            border-width: 4px;
            border-color: @cGreen;
        }
    }
    &__input:disabled + &__label{
        pointer-events: none;
        &:after{
            border-color: @cGrayEB;
            background-color: @cGrayEB;
        }
    }
    &__input._error + &__label{
        &:after{
            border-color: @cRed;
        }
    }
    ._error &__label{
        &:after{
            border-color: @cRed;
        }
    }
}
/* /Radio button */

/* Checkbox */
.checkboxes{
    margin: 15px 0 0;
    &:first-child{
        margin-top: 0;
    }
}

.checkbox{
    display: block;
    width: 100%;
    margin: 6px 0 0;
    position: relative;
    .checkboxes &{
        margin: 8px 0 0;
        &:first-child{
            margin-top: 0;
        }
    }
    &:first-child{
        margin-top: 0;
    }
    &__input{
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        width: 16px;
        height: 16px;
        z-index: 1;
        visibility: visible;
        opacity: 0;
    }
    &__label{
        .inline-block(top);
        .fontHNRegular(15px, 22px);
        color: @cBlack2;
        box-sizing: border-box;
        padding: 0 0 0 24px;
        position: relative;
        cursor: pointer;
        max-width: 100%;
        .checkboxes &{
            .fontHNRegular(13px, 19px);
        }
        a{
            text-decoration: none;
            color: @cGreen;
        }
        &:before{
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            border: 1px solid @cGray;
            background: @cWhite;
            position: absolute;
            top: 2px;
            left: 0;
            border-radius: 2px;
            z-index: 1;
            .transition(~"border-width .15s ease, border-color .15s ease");
            .checkboxes &{
                top: 1px;
            }
        }
        .no-touchevents &:hover{
            &:before{
                border-width: 2px;
                border-color: @cGreen;
            }
        }
        &:after{
            content: "";
            display: block;
            box-sizing: border-box;
            position: absolute;
            top: 2px;
            left: 0;
            z-index: 2;
            border-radius: 2px;
            .transition(~"opacity .15s ease");
            .icon-checkbox(16px, 16px);
            opacity: 0;
            .checkboxes &{
                top: 1px;
            }
        }
        &--small{
            .fontHNRegular(13px, 21px);
        }
    }
    /*&__input:focus + &__label{
        &:before{
            border-width: 2px;
            border-color: @cGreen;
        }
    }*/
    &__input:checked + &__label{
        &:after{
            opacity: 1;
        }
    }
    &__input:disabled + &__label{
        pointer-events: none;
    }
    &__input._error + &__label{
        &:before{
            border-color: @cRed;
        }
    }
    ._error &__label{
        &:before{
            border-color: @cRed;
        }
    }
}
/* /Checkbox */

/* Default fields */
.field{
    display: block;
    margin: 21px 0 0;
    position: relative;
    &:first-child{
        margin-top: 0;
    }
    &--su-checkbox{
        margin: 11px 0 0;
        &:first-child{
            margin-top: 0;
        }
    }
    &--su-checkbox+&--su-checkbox{
        margin-top: 10px;
    }
    &--submit{
        margin: 19px 0 0;
        &:first-child{
            margin-top: 0;
        }
    }
    @media screen and (max-width: 699px){
        &--sbmt{
            .button{
                width: 100%;
            }
        }
    }
    @media screen and (min-width: 700px){
        display: flex;
        margin: 20px 0 0;
        &:first-child{
            margin-top: 0;
        }
        &--near{
            margin: 10px 0 0;
            &:first-child{
                margin-top: 0;
            }
        }
        &--su-checkbox{
            margin: 11px 0 0;
            &:first-child{
                margin-top: 0;
            }
        }
        &--su-checkbox+&--su-checkbox{
            margin-top: 10px;
        }
        &--submit{
            margin: 19px 0 0;
            &:first-child{
                margin-top: 0;
            }
        }
    }
    &-hr{
        border-top: @cGrayDD solid 1px;
        margin: 30px 0;
    }
    &-hr + &--su-checkbox .checkbox{
        @media screen and (min-width: 768px){
            margin-top: 0;
        }
    }
    &-label{
        @media screen and (min-width: 700px){
            width: 150px;
            box-sizing: border-box;
            padding: 11px 10px 0 0;
        }
        &._not-padded{
            @media screen and (min-width: 700px){
                padding: 0;
            }
        }
    }
    &-input{
        @media screen and (min-width: 700px){
            width: calc(~"100% - 150px");
        }
    }
    &-text{
        display: block;
        .fontHNRegular(15px, 22px);
        margin: 21px 0 -1px;
    }
    &-subtext{
        color: @cGrayB0;
        .fontHNRegular(13px, 18px);
        margin: 10px 0 20px;
    }
}
.fields{
    &__title{
        display: block;
        .fontHNMedium(15px, 22px);
        text-transform: uppercase;
        letter-spacing: 1px;
        color: @cBlack2;
        margin: 29px 0 -3px;
    }
}
.label{
    display: block;
    .fontHNBold(15px, 20px);
    color: @cBlack2;
    margin: 0 0 9px;
    &--light{
        margin: -10px 0 10px;
        .fontHNRegular(15px, 20px);
    }
    @media screen and (min-width: 768px){
        &--middle{
            margin: -10px 0 10px;
            .fontHNRegular(15px, 20px);
        }
    }
}
.textarea{
    display: block;
    width: 100%;
    height: 120px;
    resize: none;
    border: 1px solid @cGray;
    border-radius: 3px;
    color: @cBlack2;
    .fontHNRegular(15px, 20px);
    padding: 10px 10px 8px;
    .placeholder(@opBlack2_30);
    box-sizing: border-box;
    background: @cWhite;
    word-break: keep-all;
    .transition(~"border-color .15s ease");
    &._error, &:invalid{
        border-color: @cRed;
        padding: 10px 10px 8px;
        border-width: 1px;
        box-shadow: none;
        &:focus{
            border-color: @cGreen;
            padding: 9px 9px 7px;
            border-width: 2px;
        }
    }
    .no-touchevents &:hover{
        border-color: @cGreen;
        padding: 9px 9px 7px;
        border-width: 2px;
    }
    &:focus{
        border-color: @cGreen;
        padding: 9px 9px 7px;
        border-width: 2px;
    }
    &._required{
        padding-left: 29px;
        .no-touchevents &:hover, &:focus{
            padding-left: 28px;
            ~ ._star{
                &:before{
                    opacity: 1;
                }
                &:after{
                    opacity: 0;
                }
            }
        }
    }
    &._required + ._star{
        display: block;
    }
    &._required._error + ._star{
        &:before{
            opacity: 0;
        }
        &:after{
            opacity: 1;
        }
    }
}
.input{
    display: block;
    width: 100%;
    height: 40px;
    border: 1px solid @cGray;
    border-radius: 3px;
    color: @cBlack2;
    .fontHNRegular(15px, 20px);
    padding: 9px 10px 9px;
    .placeholder(@opBlack2_30);
    box-sizing: border-box;
    background: @cWhite;
    word-break: keep-all;
    .transition(~"border-color .15s ease");
    .no-touchevents &:hover{
        border-color: @cGreen;
        padding: 8px 9px 8px;
        border-width: 2px;
    }
    &:focus{
        border-color: @cGreen;
        padding: 8px 9px 8px;
        border-width: 2px;
    }
    &--140{
        width: 140px;
    }
    &--160{
        @media screen and (min-width: 700px){
            max-width: 160px;
        }
        @media screen and (min-width: 1024px){
            max-width: none;
        }
        @media screen and (min-width: 1340px){
            max-width: 160px;
        }
    }
    &--tablet-150{
        @media screen and (min-width: 768px){
            width: 150px;
        }
    }
    &--70{
        .inline-block(top);
        width: 70px;
    }
    &--50{
        max-width: 50px;
        width: 50px;
        display: inline-block;
    }
    &._error, &:invalid{
        border-color: @cRed;
        padding: 9px 10px 9px;
        border-width: 1px;
        box-shadow: none;
        &:focus{
            border-color: @cGreen;
            padding: 8px 9px 8px;
            border-width: 2px;
        }
    }
    +._ok{
        display: block;
        position: absolute;
        top: 50%;
        right: 9px;
        .icon-success-small-green(20px, 20px);
        z-index: 10;
        margin-top: -10px;
    }
    &--with-ok{
        padding-right: 30px;
        .no-touchevents &:hover{
            padding-right: 29px;
        }
        &:focus{
            padding-right: 29px;
        }
    }
    &:disabled{
        opacity: 0.3;
        pointer-events: none;
    }
    &._required{
        padding-left: 29px;
        .no-touchevents &:hover, &:focus{
            padding-left: 28px;
            ~ ._star{
                &:before{
                    opacity: 1;
                }
                &:after{
                    opacity: 0;
                }
            }
        }
    }
    &._required + ._star{
        display: block;
    }
    &._required._error + ._star{
        &:before{
            opacity: 0;
        }
        &:after{
            opacity: 1;
        }
    }
}
.error{
    display: block;
    .fontHNRegular(13px, 19px);
    color: @cRed2;
    margin: 11px 0 -1px;
}
/* /Default fields */

/* Date range */
.date{
    display: block;
    font-size: 0;
    line-height: 0;
    width: 100%;
    margin: 15px 0 3px;
    &:first-child{
        margin-top: 0;
    }
    &-column{
        .inline-block(top);
        width: calc(~"50% - 14.5px");
        margin: 0 29px 0 0;
        position: relative;
        z-index: 10;
        &:after{
            content: "\2014";
            display: block;
            .fontHNRegular(14px, 19px);
            color: @cCalendarBetween;
            position: absolute;
            left: 100%;
            top: 50%;
            margin: -9.5px 0 0 9px;
            pointer-events: none;
        }
        &:last-child{
            margin-right: 0;
            &:after{
                content: none;
            }
        }
    }
    &__input{
        display: none;
        &._required ~ .date__text{
            padding-left: 29px;
            .no-touchevents &:hover, &:focus{
                padding-left: 28px;
                ~ ._star{
                    &:before{
                        opacity: 1;
                    }
                    &:after{
                        opacity: 0;
                    }
                }
            }
        }
        &._required ~ ._star{
            display: block;
        }
        &._error ~ .date__text{
            border-color: @cRed2;
            padding: 2px 9px 0;
            border-width: 1px;
            box-shadow: none;
        }
        &._required._error ~ .date__text{
            padding-left: 29px;
        }
        &._required._error ~ ._star{
            &:before{
                opacity: 0;
            }
            &:after{
                opacity: 1;
            }
        }
    }
    &__text{
        display: block;
        width: 100%;
        height: 36px;
        box-sizing: border-box;
        background: @cWhite;
        border: @cGray solid 1px;;
        border-radius: 3px;
        .fontHNRegular(13px, 32px);
        padding: 2px 9px 0;
        .transition(~"border-color .15s ease");
        cursor: pointer;
        .no-touchevents &:hover{
            border-width: 2px;
            border-color: @cCalendarInputBordOver;
            padding: 1px 8px 0;
        }
        &._active{
            border-width: 2px;
            border-color: @cCalendarInputBordOver;
            padding: 1px 8px 0;
        }
        &._placeholder{
            color: @opBlack2_30;
        }
    }
    .no-touchevents &-column:hover &__text, &-column._active &__text{
        border-width: 2px;
        border-color: @cCalendarInputBordOver;
        padding: 1px 8px 0;
    }
    &__btn{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background: transparent;
        border: none;
        box-shadow: none;
        font-size: 0;
        padding: 0;
        line-height: 0;
        text-decoration: none;
        img{
            display: none;
        }
        &:after{
            content: "";
            position: absolute;
            top: 50%;
            right: 10px;
            margin-top: -7px;
            .icon-calendarSmall(14px, 14px);
        }
        .no-touchevents &:hover ~ .date__text{
            border-width: 2px;
            border-color: @cCalendarInputBordOver;
            padding: 1px 8px 0;
        }
        .no-touchevents &:hover ~ .date__input._required ~ .date__text{
            padding-left: 28px;
            ~ ._star{
                &:before{
                    opacity: 1;
                }
                &:after{
                    opacity: 0;
                }
            }
        }
    }
}
/* /Date range */

/* Text checkbox */
.text-checkbox{
    .inline-block(top);
    margin: 0 10px 10px 0;
    max-width: calc(~"100% - 10px");
    &__input{
        display: none;
    }
    &__label{
        display: block;
        .fontHNRegular(13px, 24px);
        color: @cCheckboxColor;
        box-sizing: border-box;
        height: 33px;
        border: 1px solid @cCheckboxBord;
        .transition(~"border-color .15s ease, color .15s ease, background .15s ease");
        padding: 4px 9px 3px;
        cursor: pointer;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        border-radius: 3px;
        .no-touchevents &:hover{
            border-width: 2px;
            border-color: @cCheckboxBordOver;
            padding: 3px 8px 1px;
        }
        &:after{
            content: "";
            .icon-crossWhite(16px, 16px);
            display: none;
            position: absolute;
            top: 3px;
            right: 8px;
        }
    }
    &__input:checked + &__label{
        border-width: 2px;
        border-color: @cCheckboxBordOver;
        background: @cCheckboxBordOver;
        color: @cCheckboxColorActive;
        padding: 3px 24px 1px 8px;
        &:after{
            .inline-block(top);
            margin: 4px -3px 0 9px;
        }
    }
}
/* /Text checkbox */

/* d-field */
.d-field{
    display: block;
    font-size: 0;
    line-height: 0;
    margin: 32px 0 0;
    position: relative;
    &:first-child{
        margin-top: 0;
    }
    &--locked{
        margin: 20px 0 0;
        &:first-child{
            margin-top: -9px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    &-left{
        .inline-block(top);
        width: 100%;
        box-sizing: border-box;
        padding: 0;
    }
    &-right{
        .inline-block(top);
        width: 100%;
        box-sizing: border-box;
    }
    &__text{
        display: block;
        .fontHNRegular(15px, 20px);
        color: @cBlack2;
        padding-top: 3px;
    }
    &__limit{
        display: inline;
        .fontHNRegular(15px, 20px);
        color: @cBlack2;
        padding-top: 3px;
        margin: 0 0 0 10px;
    }
    &__help{
        display: block;
        .fontHNRegular(13px, 19px);
        color: @cBlack2;
        opacity: 0.3;
        margin: 10px 0 0;
    }
    &-help{
        display: block;
        .fontHNRegular(13px, 19px);
        color: @opBlack2_30;
        margin: 20px 0 0;
        &-short{
            display: block;
        }
        &-full{
            display: none;
        }
        a{
            .transition(~"color .15s ease, opacity .15s ease");
            color: @opBlack2_30;
            .no-touchevents &:hover{
                color: @cGreen;
            }
        }
        ol{
            color: @cBlack2;
            counter-reset: helpCounter;
            margin: 0 0 12px;
            a{
                color: @cBlack2;
            }
            li{
                margin: 7px 0 0;
                &:first-child{
                    margin-top: 0;
                }
                &:before{
                    counter-increment: helpCounter;
                    content: counter(helpCounter)". ";
                }
            }
        }
    }
    &__label-helper{
        display: block;
        margin: 10px 0 11px;
        color: @cBlack2;
        .fontHNRegular(13px, 19px);
    }
}

@media screen and (min-width: 700px){
    .d-field{
        margin: 10px 0 0;
        &:first-child{
            margin-top: 0;
        }
        &--checkbox{
            margin: 30px 0 0;
            &:first-child{
                margin-top: 0;
            }
        }
        &--bss-checkbox{
            margin: 31px 0 0;
            &:first-child{
                margin-top: 0;
            }
        }
        &--bss{
            margin: 20px 0 0;
            &:first-child{
                margin-top: 0;
            }
        }
        &--vat{
            margin: 20px 0 20px;
            &:first-child{
                margin-top: 0;
            }
        }
        &--locked{
            margin: -4px 0 0;
            &:first-child{
                margin-top: -9px;
            }
            &:last-child{
                margin-bottom: -10px;
            }
        }
        &-left{
            width: 130px;
            padding: 0 10px 0 0;
            margin-bottom: 0;
            &:before{
                .great-middle();
                vertical-align: top;
                height: 40px;
            }
            &--high:before{
                display: none;
            }
            .label{
                .inline-block(top);
                margin: 0;
                padding-top: 10px;
            }
            &--high .label{
                margin-top: 0;
                padding-top: 10px;
            }
            &--checkbox{
                &:before{
                    height: 22px;
                    vertical-align: middle;
                }
                .label{
                    vertical-align: middle;
                    padding-top: 0;
                    margin-top: 2px;
                }
            }
            &--150{
                width: 150px;
            }
        }
        &-right{
            width: calc(~"100% - 130px");
            &--150{
                width: calc(~"100% - 150px");
            }
        }
        &__text{
            padding: 11px 0 10px;
            &--high{
                padding-top: 0;
            }
        }
        &__help{
            display: block;
            .fontHNRegular(13px, 19px);
            color: @opBlack2_30;
            margin: 10px 0 0;
        }
        &-help{
            display: block;
            .fontHNRegular(13px, 19px);
            color: @opBlack2_30;
            margin: 20px 0 0;
            &-short{
                display: block;
            }
            &-full{
                display: none;
            }
            a{
                .transition(~"color .15s ease");
                color: @opBlack2_30;
                .no-touchevents &:hover{
                    color: @cGreen;
                }
            }
            ol{
                color: @cBlack2;
                counter-reset: helpCounter;
                margin: 0 0 12px;
                a{
                    color: @cBlack2;
                }
                li{
                    margin: 7px 0 0;
                    &:first-child{
                        margin-top: 0;
                    }
                    &:before{
                        counter-increment: helpCounter;
                        content: counter(helpCounter)". ";
                    }
                }
            }
        }
    }
}
/* /d-field */
