
@import "helpers/icons";
@import "helpers/settings";

/*-- __Fonts
------------------------------------------------------------*/

/* Fonts path */
@fontPath: '../fonts/';
/* /Fonts path */

/* Roman fonts */
@font-face {
  font-family: 'Neue Helvetica Paneuropean 55 Roman';
  src: url('../fonts/38D576_0_0.eot');
  src: url('../fonts/38D576_0_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/38D576_0_0.woff2') format('woff2'),
  url('../fonts/38D576_0_0.woff') format('woff'),
  url('../fonts/38D576_0_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Neue Helvetica Paneuropean 65 Medium';
  src: url('../fonts/38D576_1_0.eot');
  src: url('../fonts/38D576_1_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/38D576_1_0.woff2') format('woff2'),
  url('../fonts/38D576_1_0.woff') format('woff'),
  url('../fonts/38D576_1_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Neue Helvetica Paneuropean 75 Bold';
  src: url('../fonts/38D576_2_0.eot');
  src: url('../fonts/38D576_2_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/38D576_2_0.woff2') format('woff2'),
  url('../fonts/38D576_2_0.woff') format('woff'),
  url('../fonts/38D576_2_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* /Roman fonts */

/* Arabic fonts */
@font-face {
  font-family: 'Neue Helvetica Arabic 55 Roman';
  src: url('../fonts/390B20_0_0.eot');
  src: url('../fonts/390B20_0_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/390B20_0_0.woff2') format('woff2'),
  url('../fonts/390B20_0_0.woff') format('woff'),
  url('../fonts/390B20_0_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
  
@font-face {
  font-family: 'Neue Helvetica Arabic 75 Bold';
  src: url('../fonts/390B20_1_0.eot');
  src: url('../fonts/390B20_1_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/390B20_1_0.woff2') format('woff2'),
  url('../fonts/390B20_1_0.woff') format('woff'),
  url('../fonts/390B20_1_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* /Arabic fonts */