
@import "helpers/mixin";
@import "helpers/fonts";
@import "helpers/icons";
@import "helpers/settings";

/*
 * Tools panel common
 *********************************************************/

 /* Tools common */
 .tools-panel{
    position: relative;
    z-index: 20;
    .page-submenu + &{
        margin-top: -10px;
        &._live{
            margin: -20px 0 -8px;
        }
        @media screen and (min-width: 700px){
            margin-top: -5px;
        }
    }
    &:first-child{
        margin-top: -10px;
    }
    &__head{
        display: flex;
        ._live &{
            @media screen and (min-width: 768px){
                display: none;
            }
        }
    }
    &__body{
        display: none;
        border: @cToolsBodyBord solid 1px;
        border-radius: 8px;
        margin: 10px 0 0;
        padding: 19px;
        ._live &{
            margin: 3px 0 0;
            @media screen and (min-width: 768px){
                display: block;
                padding: 20px 0 5px;
                border: none;
                margin: 0;
            }
        }
        ._vis &{
            display: block
        }
        @media screen and (min-width: 1024px){
            display: block;
            padding: 20px 0 5px;
            border: none;
            margin: 0;
        }
    }
    &__footer{
        display: block;
    }
 }
 /* /Tools common */

 /*
 * Tools panel head
 *********************************************************/

/* Tools count */
.tools-count{
    display: block;
    min-width: 5px;
    padding: 13px 0 6px;
    width: calc(100% - 45px);
    position: relative;
    @media screen and (min-width: 768px){
        padding: 13px 0 6px;
    }
    &__count{
        display: block;
        .fontHNRegular(13px, 19px);
        color: @cBlack2;
    }
}
/* /Tools count */

.tools-search{
    width: calc(100% - 85px);
    position: relative;
    z-index: 20;
    height: 40px;
    /*opacity: 0;*/
    .transition(~"opacity .15s ease .3s");
    /*._loaded &{
        opacity: 1;
    }*/
    &:before{
        content: '';
        display: block;
        position: absolute;
        .icon-searchBlack(20px, 20px);
        left: 12px;
        top: 11px;
        pointer-events: none;
        z-index: 5;
    }
        &.is-mobile {
          &:before {
            display: none;
          }
        }
    &._loading{
        &:before{
            .icon-loader-search(20px, 20px);
            -webkit-animation: rotating 1s linear infinite;
            -moz-animation: rotating 1s linear infinite;
            -ms-animation: rotating 1s linear infinite;
            -o-animation: rotating 1s linear infinite;
            animation: rotating 1s linear infinite;
        }
    }
    &__input{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        color: @cToolsSerachField;
        .fontHNRegular(15px, 20px);
        padding: 9px 25px 9px 41px;
        .placeholder(@opBlack2_30);
        box-sizing: border-box;
        background: @cToolsSerachFieldBG;
        word-break: keep-all;
        border: none;
        .transition(~"all .15s ease");
        ._focused &{
            width: calc(100% + 10px);
        }
        ._ios &{
            padding-top: 11px;
            padding-bottom: 7px;
        }
        @media screen and (min-width: 1024px){
            width: calc(100% + 10px);
            padding-right: 35px;
        }
    }
    &__reset{
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        background: none;
        border: none;
        top: 10px;
        right: 5px;
        z-index: 5;
        opacity: 0;
        visibility: hidden;
        .transition(~"all .15s ease");
        &:before{
            content: '';
            display: block;
            .icon-toolsPanelReset(16px, 16px);
            position: absolute;
            left: 2px;
            top: 2px;
        }
        &._vis{
            opacity: 1;
            visibility: visible;
        }
    }
    &__submit{
        position: absolute;
        width: 80px;
        top: 0;
        right: -45px;
        opacity: 0;
        visibility: hidden;
        z-index: 5;
        border-radius: 0 3px 3px 0;
        .transition(~"all .15s ease");
        ._focused &{
            right: -85px;
            opacity: 1;
            visibility: visible;
        }
        @media screen and (min-width: 1024px){
            right: -85px;
            opacity: 1;
            visibility: visible;
        }
    }
    &-body{
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        right: -85px;
        z-index: 10;
        background: @cWhite;
        border-radius: 3px;
        max-height: 349px;
        overflow: auto;
        box-shadow: 0 8px 22px @opShadow20;
        opacity: 0;
        visibility: hidden;
        .transition(~"opacity .15s ease, visibility .15s ease");
        &._vis{
            opacity: 1;
            visibility: visible;
        }
        @media screen and (min-width: 1024px){
            max-height: none;
        }
    }
    &-list{
        display: block;
    }
    &-item{
        display: block;
        border-top: 1px solid @cGrayF2;
        &:first-child{
            border-top: 0;
        }
    }
    &__link{
        display: block;
        .fontHNRegular(15px, 22px);
        color: @cBlack2;
        padding: 14px 20px 12px;
        text-decoration: none;
        position: relative;
        .transition(~"color .15s ease, background .15s ease");
        &--recent{
            padding-left: 45px;
            &:before{
                content: '';
                display: block;
                position: absolute;
                top: 14px;
                left: 20px;
                .icon-recent(20px, 20px);
                z-index: 1;
                opacity: 1;
                .transition(~"opacity .15s ease");
            }
            &:after{
                content: '';
                display: block;
                position: absolute;
                top: 14px;
                left: 20px;
                .icon-recent-white(20px, 20px);
                z-index: 2;
                opacity: 0;
                .transition(~"opacity .15s ease");
            }
        }
        .no-touchevents &:hover{
            color: @cWhite;
            background: @cGreen;
            &:before{
                opacity: 0;
            }
            &:after{
                opacity: 1;
            }
        }
    }
    &-not-found{
        padding: 14px 20px 12px;
        &__title{
            display: block;
            color: @cBlack2;
            .fontHNRegular(15px, 22px);
        }
        &__info{
            display: block;
            color: @cBlack2;
            opacity: 0.3;
            .fontHNRegular(13px, 19px);
            margin: 5px 0 0;
        }
    }
    &-recent{
        position: relative;
        padding: 21px 20px 12px;
        border-bottom: 1px solid @cGrayF2;
        &__title{
            display: block;
            color: @cBlack2;
            .fontHNMedium(15px, 22px);
            letter-spacing: 1px;
            text-transform: uppercase;
        }
        &__clear{
            background: transparent;
            display: block;
            border: 0;
            box-shadow: none;
            color: @cGreen;
            position: absolute;
            top: 21px;
            right: 21px;
            text-decoration: none;
            padding: 0;
            .fontHNRegular(13px, 19px);
        }
    }
    &__pill{
        background: #77bc1f;
        border-radius: 3px;
        color: #fff;
        display: flex;
        padding: 5px 10px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        margin: 5px;
        width: 165px;
        box-sizing: border-box;
        cursor: pointer;
        &--label{
            .fontHNRegular(15px, 20px);
            margin: auto;
            font: normal 400 15px/20px 'Neue Helvetica Paneuropean 55 Roman', sans-serif /* rtl: normal 400 15px/20px Arial, sans-serif */;
            border: none;
            color: #fff;
            padding: 0 5px 0 0;
            height: initial;
            svg{
                margin-left: 5px;
            }
        }
    }
 }
.no-touchevents .tools-search__pill--label.dropdown__head:hover, .tools-search__pill--label.dropdown__head._opened {
    padding: 0 5px 0 0;
}
.no-touchevents .tools-search__pill--label.dropdown__head:hover:before {
    margin-right: 0;
}
.tools-search .dropdown-body{
    min-width: 115px;
}
.tools-search__pill--label.dropdown__head:before{
    content: "";
    display: block;
    position: absolute;
    right: 2px;
    top: 7px;
    .icon-dropdownWhite(11px, 6px);
    margin: 0;
    z-index: 1;
    opacity: 1;
    .transition(~"opacity .15s ease");
}

.tools-search-landing.tools-search:before {
    left: 175px;
}
.tools-search-landing .tools-search__input {
    padding-left: 200px;
}
.tools-search-landing .dropdown-body--to-list {
    min-width: 115px;
}
 /* /Tools search */

/* Tools button */
.tools-btn{
    display: block;
    border: none;
    background: none;
    width: 40px;
    height: 40px;
    position: relative;
    @media screen and (min-width: 1024px){
        display: none;
    }
    &:before{
        content: '';
        display: block;
        position: absolute;
        left: 9px;
        top: 9px;
        opacity: 1;
        .transition(~"opacity .15s ease");
    }
    &:after{
        content: '';
        display: block;
        position: absolute;
        left: 9px;
        top: 9px;
        opacity: 0;
        z-index: 1;
        .transition(~"opacity .15s ease");
    }
    .no-touchevents &:hover, &._active{
        &:before{
            opacity: 0;
        }
        &:after{
            opacity: 1;
        }
    }
    &--filter{
        left: 11px;
        &:before{
            .icon-toolsPanelFilter(22px, 22px);
        }
        &:after{
            .icon-toolsPanelFilterActive(22px, 22px);
        }
        &._filled:before{
            .icon-toolsPanelFilterFilled(22px, 22px);
            opacity: 1;
        }
        &._filled:after{
            display: none;
        }
    }
    &--settings{
        left: 13px;
        &:before{
            .icon-toolsPanelSettings(22px, 22px);
        }
        &:after{
            .icon-toolsPanelSettingsActive(22px, 22px);
        }
    }
}
/* /Tools button */

 /*
 * Tools panel body
 *********************************************************/

 /* Tools panel settings */
 .ae-filter{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 768px){
        flex-wrap: nowrap;
        display: grid;
        width: 100%;
        grid-template-columns: 100px 1fr 160px;
        grid-gap: 20px;
    }
    @media screen and (min-width: 1024px){
        grid-template-columns: 100px 1fr 273px;
    }
    &--tl{
        @media screen and (min-width: 550px){
            flex-wrap: nowrap;
            display: grid;
            width: 100%;
            grid-template-columns: 150px 1fr;
            grid-gap: 20px;
            .ae-filter-relevant{
                margin-top: 0;
            }
        }
        @media screen and (min-width: 768px){
            grid-template-columns: 150px 1fr 160px;
        }
        @media screen and (min-width: 1024px){
            grid-template-columns: 150px 1fr 273px;
        }
    }
    &-count{
        display: none;
        min-width: 5px;
        text-align: right;
        order: 2;
        @media screen and (min-width: 768px){
            display: block;
        }
        &__count{
            .fontHNRegular(13px, 19px);
            color: @cBlack2;
            display: none;
            @media screen and (min-width: 768px){
                display: block;
            }
        }
    }
    &-time{
        width: calc(100% - 110px);
        margin: 0 0 0 10px;
        order: 2;
        @media screen and (min-width: 768px){
            width: auto;
            margin: 0;
            order: 3;
        }
    }
    &-view{
        display: block;
        min-width: 5px;
        height: 30px;
        order: 1;
        width: 100px;
        &--tl{
            width: 100%;
            @media screen and (min-width: 768px){
                width: 150px;
            }
        }
    }
    &-relevant{
        display: block;
        margin: 10px 0 0;
        width: 100%;
        order: 3;
        &:empty{
            display: none;
            @media screen and (min-width: 768px){
                display: block;
            }
        }
        &__mobile{
            display: block;
            @media screen and (min-width: 1024px){
                display: none;
            }
        }
        &__desktop{
            display: none;
            @media screen and (min-width: 1024px){
                display: block;
            }
            &--cookies{
                display: block;
            }
        }
        @media screen and (min-width: 768px){
            order: 2;
            width: 130px;
            margin: 0;
        }
        @media screen and (min-width: 1024px){
            width: auto;
        }
    }
}
/* /Tools panel settings */

/* Tools sort type */
.ae-sort{
    display: flex;
    min-width: 100px;
    width: 0;
    &__item{
        box-sizing: border-box;
        border-top: @cGray solid 1px;
        border-bottom: @cGray solid 1px;
        padding: 4px 15px 0;
        height: 30px;
        flex-basis: 50px;
        flex-grow: 1;
        text-align: center;
        color: @cBlack2;
        text-decoration: none;
        white-space: nowrap;
        .fontHNRegular(13px, 19px);
        .transition(~"border-color .15s ease, background .15s ease");
        ._ios &{
            padding-top: 6px;
        }
        .no-touchevents._ios &:hover{
            padding-top: 5px;
        }
        &:first-child{
            border-radius: 3px 0 0 3px;
            border-left: @cGray solid 1px;
            .no-touchevents &:hover{
                border-left-width: 2px;
                padding-left: 14px;
                padding-right: 15px;
            }
            &._active{
                border-left-width: 1px !important;
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
        }
        &:last-child{
            border-radius: 0 3px 3px 0;
            border-right: @cGray solid 1px;
            .no-touchevents &:hover{
                border-right-width: 2px;
                padding-right: 14px;
                padding-left: 15px;
            }
            &._active{
                border-right-width: 1px !important;
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
        }
        .no-touchevents &:hover{
            border-color: @cGreen;
            border-top-width: 2px;
            border-bottom-width: 2px;
            padding: 3px 15px 0 15px;
        }
        &._active{
            background: @cGreen;
            border-color: @cGreen;
            color: @cWhite;
        }
        &._disabled{
            color: #cccccc;
            border-color: #cccccc;
            .no-touchevents &:hover{
                border-color: #cccccc;
                border-width: 1px;
                cursor: default;
            }
        }
    }
}
/* /Tools sort type */

/*
 * Tools panel footer
 *********************************************************/

/* Tools found results */
.tools-found{
    color: @cBlack2;
    .fontHNRegular(13px, 19px);
    margin: 11px 0 0;
    @media screen and (min-width: 1340px){
        margin: 0;
        position: absolute;
        bottom: 9px;
        right: 294px;
    }
    &__all{
        display: block;
        @media screen and (min-width: 768px){
            display: inline;
        }
    }
    &__local{
        display: block;
        margin: 12px 0 0;
        @media screen and (min-width: 768px){
            display: inline;
            margin: 0;
        }
    }
    &__mobile{
        display: inline;
        @media screen and (min-width: 768px){
            display: none;
        }
    }
    &__tablet{
        display: none;
        @media screen and (min-width: 768px){
            display: inline;
        }
    }
    &__mobile&__tablet{
        display: inline;
        @media screen and (min-width: 1024px){
            display: none;
        }
    }
    &__mobile&__desktop{
        display: inline;
        @media screen and (min-width: 768px){
            display: none;
        }
        @media screen and (min-width: 1024px){
            display: inline;
        }
    }
    &__desktop{
        display: none;
        @media screen and (min-width: 1024px){
            display: inline;
        }
    }
    &__link{
        text-decoration: none;
        color: @cGreen;
    }
}
/* /Tools found results */
