@import "helpers/mixin";
@import "helpers/fonts";
@import "helpers/icons";
@import "helpers/settings";

.float-header {
  display: block;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  font-size: 0;
  line-height: 0;
  background: @cHeaderBG;
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  .transition(~"box-shadow .15s ease, background .15s ease");

  &--landing {
    .transition(~"box-shadow .15s ease, background .15s ease, opacity .15s ease");

    @media screen and (max-width: 700px) {
      body:not(.no-touchevents) & {
        .header__search {
          visibility: hidden;
        }
      }
    }
  }

  &._fixed {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    box-shadow: 0 8px 30px @opShadow15;

    &._blend {
      box-shadow: none;
      -webkit-backdrop-filter: blur(9px);
      backdrop-filter: blur(9px);
      background-color: @opHeaderBlend;
    }

    ~ .header .header__slogan {
      opacity: 0;
    }

    ._popup & {
      opacity: 0 !important;
      visibility: hidden !important;
      box-shadow: none !important;
      pointer-events: none !important;
    }
  }

  ._popup & {
    display: none !important;
  }

  @media screen and (min-width: 1024px) {
    overflow-y: visible;
  }
}

.header {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  font-size: 0;
  line-height: 0;
  background: @cHeaderBG;
  z-index: 60;
  max-height: 100%;
  box-sizing: border-box;
  .transition(~"box-shadow .15s ease, background .15s ease");

  @-moz-document url-prefix() {
    max-height: inherit;
  }

  &--clear {
    background: transparent;
    padding: 10px 0 0;
    margin: 0 0 -28px;
    @media screen and (min-width: 700px) {
      padding: 12px 0 0;
      margin: 0 0 -18px;
    }
  }

  &--error {
    background: transparent;
    padding: 10px 0 0;
    margin: 0 0 -28px;
    @media screen and (min-width: 700px) {
      padding: 30px 0 0;
      margin: 0 0 -18px;
    }
  }

  &--landing {
    background: transparent;
    z-index: 4;

    @media screen and (max-width: 700px) {
      body:not(.no-touchevents) & {
        .header__search {
          visibility: hidden;
        }
      }
    }

    & .header-mm {
      position: absolute;
      width: 100%;
    }
  }

  &-before {
    display: block;
    width: 100%;
  }

  &-main {
    display: block;
    width: 100%;
  }

  &-after {
    display: block;
    width: 100%;
  }

  &._fixed {
    box-shadow: 0 0 21px @cHeaderShadow;
  }

  &-inner {
    display: block;
    position: relative;
    box-sizing: border-box;
    height: 62px;
    padding: 20px 0 20px;
  }

  &-logo {
    .inline-block(top);
    text-decoration: none;

    &__img {
      display: block;
      .icon-logo(109px, 23px);

      &--black {
        .icon-logoDark(109px, 23px);
      }
    }
  }

  &__mm {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 0;
    line-height: 0;
    z-index: 2;
    text-decoration: none;
    margin: -9px 1px 0;
    .transition(~"color .15s ease");
    padding: 0;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border-top: 2px solid @cWhite;
    border-bottom: 2px solid @cWhite;

    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      margin: -1px 0 0 0;
      width: 100%;
      height: 2px;
      background: @cWhite;
    }

    &._opened {
      border: 0;
      margin-right: 0;

      &:before {
        .icon-close-white(20px, 20px);
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -10px 0 0 -11px;
      }
    }
  }

  &__search {
    display: block;
    position: absolute;
    top: 50%;
    right: 50px;
    font-size: 0;
    line-height: 0;
    z-index: 2;
    text-decoration: none;
    .transition(~"color .15s ease");
    padding: 0;
    margin: -9.5px 0 0;
    width: 19px;
    height: 19px;
    box-sizing: border-box;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      .icon-search(100%, 100%);
      z-index: 1;
      opacity: 1;
      .transition(~"opacity .15s ease");
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      .icon-searchGreen(100%, 100%);
      z-index: 2;
      opacity: 0;
      .transition(~"opacity .15s ease");
    }

    .no-touchevents &:hover {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }

    @media screen and (min-width: 768px) {
      right: 60px;
    }
  }

  &__slogan {
    display: block;
    text-transform: uppercase;
    .fontHNBold(12px, 15px);
    color: @cSlogan;
    letter-spacing: 1px;
    margin: -9px 0 0;
    padding: 0 0 20px;
    opacity: 1;
    .transition(~"opacity .15s ease");

    &--tablet {
      display: none
    }

    @media screen and (min-width: 768px) {
      &--all {
        display: none
      }

      &--tablet {
        display: block
      }

      position: absolute;
      bottom: 23px;
      padding: 0;
      left: 130px;
    }
    @media screen and (min-width: 1024px) {
      &--all {
        display: block
      }

      &--tablet {
        display: none
      }

      position: relative;
      margin: -5px 0 0;
      left: 0;
      bottom: 0;
      padding: 0 0 20px;
    }

    ._popup & {
      opacity: 1 !important;
    }
  }
}

.header-cookies {
  display: block;
  position: relative;
  background: @cGray2;
  box-sizing: border-box;
  bottom: 0;
  overflow: hidden;
  height: 0;

  &__text {
    display: block;
    .fontHNRegular(13px, 15px);
    color: @cCookie;
    opacity: 1;
    max-width: 330px;

    a {
      color: @cGreen;
      text-decoration: none;
    }
  }

  &-inner {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 1024px) {
      // display: block;
      flex-direction: row;
      justify-content: center;
    }
  }

  .button&__button {
    position: relative;
    top: 15px;
    width: 120px;
    height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 20px;
    color: #ffffff;
    font: normal 400 13px/19px 'Neue Helvetica Paneuropean 55 Roman', sans-serif /* rtl: normal 400 13px/19px Arial, sans-serif */;

    .no-touchevents &:hover {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  .btn-bord.button&__button {
    &:hover {
      box-shadow: initial;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
    @media screen and (min-width: 768px) {
      margin-top: 15px;
      width: initial;
    }
    @media screen and (min-width: 1024px) {
      display: inline-block;
      flex-direction: column-reverse;
    }
  }
}

.float-header .header-cookies {
  opacity: 0;
  .transition(~"opacity 0s ease");
}

.float-header._fixed .header-cookies {
  opacity: 1;
}

.header-cookies-float {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 103;
  font-size: 0;
  line-height: 0;
}

@media screen and (min-width: 700px) {
  .header-cookies {
    &__text {
      max-width: 400px;
    }

    .button&__button {
      width: 120px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .header-cookies {
    text-align: center;

    &__text {
      // .inline-block();
      // max-width: calc(~"100% - 99px");
      text-align: left;
      margin: 0 15px 0 0;
    }

    .button&__button {
      right: 0;
      top: 0;
      margin: 5px 0;
      // .inline-block();
    }
  }
}

[dir=ltr] .header-cookies__button {
  font: normal 400 13px/19px 'Neue Helvetica Paneuropean 55 Roman', sans-serif /* rtl: normal 400 13px/19px Arial, sans-serif */;
}

.header-search {
  display: block;
  position: absolute;
  bottom: 11px;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  .transition(~"opacity .15s ease, visibility .15s ease");

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 11px;
    left: 12px;
    border: 0;
    box-shadow: none;
    box-sizing: border-box;
    .icon-searchBlack(19px, 19px);
  }

  &._loading {
    &:before {
      .icon-loader-search(20px, 20px);
      -webkit-animation: rotating 1s linear infinite;
      -moz-animation: rotating 1s linear infinite;
      -ms-animation: rotating 1s linear infinite;
      -o-animation: rotating 1s linear infinite;
      animation: rotating 1s linear infinite;
    }
  }

  &._vis {
    opacity: 1;
    visibility: visible;
  }
}

.header-menu {
  display: none;
  background: @cHeaderMenuBG;

  &-item {
    border-bottom: 1px solid @cHeaderMenuBorder;
  }

  &__link {
    display: block;
    height: 60px;
    box-sizing: border-box;
    padding: 19px 20px 18px;
    .fontHNRegular(15px, 23px);
    text-align: left;
    color: @cHeaderMenuColor;
    text-decoration: none;
    .transition(~"background .15s ease, color .15s ease");
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0;
      background: @cHeaderMenuActive;
      .transition(~"width .15s ease");
    }

    &._active {
      &:before {
        width: 6px;
      }
    }

    .no-touchevents &:hover {
      color: @cHeaderMenuColorOver;
      background: @cHeaderMenuBGOver;
    }
  }

  &-profile {
    background: @cHeaderMenuProfile;
    color: @cHeaderMenuProfileText;
    padding: 22px 20px 19px;

    &-item {
      margin: 10px 0 0;

      &:first-child {
        margin-top: 0;
      }
    }

    &__title {
      display: block;
      .fontHNRegular(13px, 19px);
    }

    &__mail {
      display: block;
      .fontHNRegular(15px, 20px);
      text-decoration: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__num {
      display: block;
      .fontHNRegular(15px, 20px);
    }
  }

  &-search {
    display: block;
    border-top: 1px solid @opGrayF2_30;
    border-bottom: 1px solid @cHeaderMenuBorder;
    background: @cHeaderBG;

    &-head {
      height: 60px;
      position: relative;
    }

    &__input {
      display: block;
      width: 100%;
      height: 45px;
      box-sizing: border-box;
      border: 0;
      border-radius: 0;
      background: transparent;
      padding: 13px 15px 0 40px;
      color: @cHeaderSearchColor;
      .fontHNRegular(13px, 21px);
      .placeholder(@cHeaderSearchPlaceholder, normal);
      .transition(~"border-color .15s ease");
    }

    &__submit {
      display: block;
      position: absolute;
      left: 15px;
      top: 20px;
      margin: 0;
      .icon-search(19px, 19px);
      border: 0;
      box-shadow: none;
    }

    &-body {
      display: none;
      background: @cWhite;
    }
  }
}

.header-submenu {
  display: block;
  background: @cHeaderMenuBG;

  &-item {
    border-top: 1px solid @cHeaderMenuBorder;
  }

  &__link {
    display: block;
    min-height: 50px;
    box-sizing: border-box;
    padding: 15px 20px 15px 40px;
    .fontHNRegular(15px, 20px);
    text-align: left;
    color: @cHeaderMenuColor;
    text-decoration: none;
    .transition(~"background .15s ease, color .15s ease");
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0;
      background: @cHeaderMenuActive;
      .transition(~"width .15s ease");
    }

    &._active {
      &:before {
        width: 6px;
      }
    }

    .no-touchevents &:hover {
      color: @cHeaderMenuColorOver;
      background: @cHeaderMenuBGOver;
    }
  }
}

.header-profile {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  text-align: right;
  margin: -10px 0 0;

  &-link {
    .inline-block(top);
    margin: 0 0 0 30px;
  }

  &__link {
    display: block;
    text-decoration: none;
    text-transform: none;
    .fontHNMedium(15px, 24px);
    color: @cHeaderColor;
    letter-spacing: 0;
    .transition(~"color .15s ease");

    .no-touchevents &:hover {
      color: @cMenuColorOver;
    }

    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0;
      height: 3px;
      background: @cMenuColorOver;
      .transition(~"width.15s ease");
    }
  }

  &__helper {
    display: none;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 18px;
    height: 3px;
    border-bottom: 3px solid @cGreen;
  }

  &__open {
    display: block;
    position: relative;
    .fontHNRegular(15px, 23px);
    letter-spacing: 0;
    z-index: 2;
    text-decoration: none;
    text-transform: none;
    color: @cHeaderColor;
    .transition(~"color .15s ease");
    padding: 0 18px 0 0;
    white-space: nowrap;

    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 2px;
      top: 8.5px;
      .icon-dropdownWhite(11px, 6px);
      margin: 0;
      z-index: 1;
      opacity: 1;
      .transition(~"opacity .15s ease");
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 2px;
      top: 8.5px;
      .icon-dropdownGreen(11px, 6px);
      margin: 0;
      z-index: 2;
      opacity: 0;
      .transition(~"opacity .15s ease");
    }

    &._active {
      .header-profile__helper {
        display: block;
      }
    }

    .no-touchevents &:hover {
      color: @cHeaderColorOver;

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }

      &._active {
        color: @cHeaderColorOver;

        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &-block {
    display: none;
    background: @cHeaderMenuBG;
    width: 300px;
    position: absolute;
    z-index: 51;
    top: calc(~"50% + 15px");
    right: calc(~"50% - 439px");
    margin: 0;
    box-shadow: 0 6px 24px @cHeaderMenuShadow;

    ._fixed & {
      top: calc(~"50% + 17px");
    }
  }

  &-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: auto;
    right: 20px;
    padding-top: 5px;
    z-index: 2;
    width: 270px;
    .transition(~"opacity .3s ease, visibility .3s ease");

    &__list {
      background: @cHeaderMenuBG;
      box-shadow: 0 8px 22px @opShadow20;
      border-radius: 8px;
      overflow: hidden;
    }

    &__item {
      border-bottom: 1px solid @cHeaderMenuBorder;

      &:last-child {
        border-bottom: 0;
      }
    }

    &__link {
      display: block;
      min-height: 45px;
      box-sizing: border-box;
      padding: 15px 14px 11px;
      .fontHNRegular(13px, 19px);
      text-align: left;
      color: @cHeaderMenuColor;
      text-decoration: none;
      .transition(~"background .15s ease, color .15s ease");
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        background: @cHeaderMenuActive;
        .transition(~"width .15s ease");
      }

      &._active {
        &:before {
          width: 6px;
        }
      }

      .no-touchevents &:hover {
        color: @cHeaderMenuColorOver;
        background: @cHeaderMenuBGOver;

        .header-profile-menu__subtext {
          color: @cHeaderMenuColorOver;
        }
      }
    }

    &__subtext {
      display: block;
      width: 100%;
      margin: 7px 0 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: @cGrayB0;
      .fontHNRegular(13px, 18px);
    }
  }

  .no-touchevents &-link:hover &-menu {
    opacity: 1;
    visibility: visible;
  }

  .no-touchevents &-link:hover &__open {
    color: @cMenuColorOver;

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}

.header-mm {
  display: none;
  background: @cWhite;

  .float-header & {
    box-shadow: 0 6px 24px @opShadow;
  }

  @media screen and (min-width: 1024px) {
    body:not(._like-mobile) & {
      display: none !important;
    }
  }

  &-item {
    display: block;
    border-top: 1px solid @cGrayF2;

    &:first-child {
      border-top: 0;
    }
  }

  &__link {
    display: block;
    background: @cWhite;
    color: @cBlue;
    .fontHNRegular(13px, 19px);
    padding: 14px 15px 12px;
    position: relative;
    text-decoration: none;
    margin-left: 0;
    margin-left: ~"constant(safe-area-inset-left)";
    margin-left: ~"env(safe-area-inset-left)";

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 5px;
      background: @cGreen;
      opacity: 0;
      .transition(~"opacity .15s ease");
    }

    &._active:before {
      opacity: 1;
    }
  }
}

.header-lang {
  display: block;
  background: @cHeaderLang;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  .transition(~"background .15s ease");

  .header--landing & {
    background: transparent;
  }

  &-list {
    display: block;
    font-size: 0;
    line-height: 0;
    margin-right: -15px;
  }

  &-item {
    .inline-block(top);

    &:first-child {
      margin-left: 0;
    }

    @media screen and (min-width: 700px) {
      position: relative;
    }

    .no-touchevents &._subitem:hover, &._subitem._opened {
      .header-lang-block {
        opacity: 1;
        visibility: visible;
      }

      .header-lang__link {
        background: @cWhite;
        color: @cBlack2;

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__link {
    display: block;
    position: relative;
    height: 30px;
    padding: 10px 8px 0;
    box-sizing: border-box;
    text-transform: uppercase;
    color: @cWhite;
    .fontHNBold(12px, 12px);
    letter-spacing: 1px;
    .transition(~"color .15s ease, background .15s ease");
    text-decoration: none;

    .no-touchevents &:hover {
      background: @cGreen;
    }

    &._active {
      background: @cGreen;
    }

    ._subitem & {
      cursor: pointer;
      padding-right: 24px;
      position: relative;

      .no-touchevents &:hover {
        background: @cWhite;
        color: @cBlack2;

        &:after {
          opacity: 1;
        }
      }
    }

    ._subitem._opened & {
      background: @cWhite;
      color: @cBlack2;

      &:after {
        opacity: 1;
      }
    }

    ._subitem &:before {
      content: '';
      .inline-block();
      .icon-dropdownWhite(12px, 12px);
      position: absolute;
      right: 7px;
      top: 10px;
    }

    ._subitem &:after {
      content: '';
      .inline-block();
      .icon-dropdownGray(12px, 12px);
      position: absolute;
      right: 7px;
      top: 10px;
      opacity: 0;
      .transition(~"opacity .15s ease");
    }
  }
}

.header-lang-block {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  background: @cWhite;
  z-index: 5;
  padding: 16px 0 20px;
  opacity: 0;
  visibility: hidden;
  .transition(~"opacity .15s ease, visibility .15s ease");
  box-shadow: 0 6px 8px @opShadow;
  @media screen and (min-width: 700px) {
    border-radius: 0 8px 8px 8px;
    width: 293px;
    padding: 16px 20px 20px;
    box-sizing: border-box;
    .iwrap {
      padding: 0 !important;
    }
  }

  &__text {
    color: @cBlack2;
    .fontHNRegular(13px, 17px);
    margin-bottom: 9px;
  }

  &__select {
    display: flex;
    position: relative;
    max-width: 232px;

    .lang-panel-button {
      flex-basis: 0;
      flex-grow: 1;
      text-align: center;
    }
  }
}

.events-search-radio {
  visibility: hidden;
  position: absolute;
}

.events-search-button {
  .inline-block(top);
  flex-basis: 0;
  flex-grow: 1;
  box-sizing: border-box;
  .fontHNRegular(13px, 24px);
  color: @cSearchFilterButtonText;
  border-top: @cSearchFilterButtonBord solid 1px;
  border-bottom: @cSearchFilterButtonBord solid 1px;
  border-left: @cSearchFilterButtonBord solid 1px;
  padding: 1px 10px 3px;
  text-decoration: none;
  text-align: center;
  .transition(~"color .15s ease, background .15s ease, border-color .15s ease");
  cursor: pointer;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 2px;
    margin-left: -1px;
    bottom: -1px;
    background: @cGreen;
    opacity: 0;
    .transition(~"opacity .15s ease");
  }

  label&:first-of-type {
    border-radius: 3px 0 0 3px;
    padding-right: 11px;
  }

  &:last-child {
    border-right: @cSearchFilterButtonBord solid 1px;
    border-radius: 0 3px 3px 0;

    &:before {
      display: none;
    }
  }

  .no-touchevents &:hover {
    border-width: 2px;
    border-color: @cGreen;
    padding: 0px 9px 2px;
  }

  .no-touchevents label&:first-of-type:hover {
    padding-right: 11px;
    border-right: 0 !important;
  }

  .no-touchevents &:last-child:hover {
    border-left-width: 1px !important;
    padding-left: 10px;
  }
}

.events-search-radio:checked + .events-search-button {
  color: @cSearchFilterButtonActiveText;
  background: @cSearchFilterButtonActive;
  border-color: @cSearchFilterButtonActive;
  z-index: 2;

  &:before {
    opacity: 1;
  }
}

.events-search-radio:checked + .events-search-button + .events-search-radio + .events-search-button {
  border-left-color: @cSearchFilterButtonActive;
}

.menu {
  display: none;
  position: relative;
  margin: 0;
  @media screen and (min-width: 1024px) {
    max-width: calc(~"100% - 380px");
  }
  opacity: 0;
  pointer-events: none;
  .transition(~"opacity .15s ease");

  &._vis {
    opacity: 1;
    pointer-events: all;
  }

  &-list {
    @media screen and (min-width: 1024px) {
      white-space: nowrap;
    }
  }

  &-item {
    .inline-block(top);
    position: relative;
    margin: 0 0 0 30px;

    &:first-child {
      margin-left: 0;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -5px;
      height: 5px;
    }

    &._hide {
      display: none;
    }
  }

  &-more {
    display: block;
    position: relative;
    width: 17px;
    height: 19px;
    top: 4px;

    &:before, &:after {
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      top: 8px;
      .transition(~"background .15s ease, border-color .15s ease");
    }

    &:before {
      width: 100%;
      box-sizing: border-box;
      border-left: 3px solid @cWhite;
      border-right: 3px solid @cWhite;
    }

    &:after {
      width: 3px;
      left: 7px;
      background: @cWhite;
    }

    .no-touchevents &:hover {
      &:before {
        border-left: 3px solid @cGreen;
        border-right: 3px solid @cGreen;
      }

      &:after {
        background: @cGreen;
      }
    }
  }

  &__link {
    display: block;
    position: relative;
    .fontHNMedium(15px, 24px);
    color: @cMenuColor;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0;
    .transition(~"color .15s ease");

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0;
      height: 3px;
      background: @cMenuColorOver;
      .transition(~"width.15s ease");
    }

    .no-touchevents &:hover {
      color: @cMenuColorOver;
    }

    &._active {
      &:before {
        width: 100%;
      }
    }
  }

  &-submenu {
    display: block;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 5px;
    z-index: 2;
    min-width: 141px;
    max-width: 200px;
    background: @cHeaderMenuBG;
    box-shadow: 0 8px 22px @opShadow20;
    border-radius: 8px;
    overflow: hidden;
    .transition(~"opacity .3s ease, visibility .3s ease");

    &__item {
      border-bottom: 1px solid @cHeaderMenuBorder;

      &:last-child {
        border-bottom: 0;
      }

      &._hide {
        display: none;
      }
    }

    &__link {
      display: block;
      min-height: 45px;
      box-sizing: border-box;
      padding: 15px 14px 11px;
      .fontHNRegular(13px, 19px);
      text-align: left;
      color: @cHeaderMenuColor;
      text-decoration: none;
      .transition(~"background .15s ease, color .15s ease");
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        background: @cHeaderMenuActive;
        .transition(~"width .15s ease");
      }

      &._active {
        &:before {
          width: 6px;
        }
      }

      .no-touchevents &:hover {
        color: @cHeaderMenuColorOver;
        background: @cHeaderMenuBGOver;
      }
    }
  }

  .no-touchevents &-item:hover &-submenu {
    opacity: 1;
    visibility: visible;
  }

  .no-touchevents &-item:hover &__link {
    color: @cMenuColorOver;
  }
}

.header-zone {
  display: block;
  background: @cHeaderBG;

  &-inner {
    padding: 21px 0 16px;
    position: relative;
    @media screen and (min-width: 768px) {
      padding: 20px 0 20px 118px;
    }
    @media screen and (min-width: 1024px) {
      padding: 16px 0 20px 118px;
    }
  }

  &-field {
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 768px) {
      align-items: center;
    }
  }

  &__label {
    display: block;
    width: 100%;
    color: @cWhite;
    .fontHNRegular(15px, 22px);
    margin: 0 0 3px;
    @media screen and (min-width: 768px) {
      width: auto;
      margin: 2px 10px 0 0;
    }
  }

  &-dropdown {
    display: block;
    width: calc(100% - 98px);
    @media screen and (min-width: 768px) {
      width: 170px;
    }
  }

  &-button {
    display: block;
    width: 88px;
    margin: 0 0 0 10px;
    @media screen and (min-width: 768px) {
      width: 70px;
    }
  }

  &__button {
    width: 100%;
  }

  &-text {
    display: block;
    width: calc(~"100% - 98px");
    color: @cWhite;
    opacity: 0.6;
    .fontHNRegular(13px, 18px);
    margin: 10px 0 0;
    min-height: 36px;
    @media screen and (min-width: 768px) {
      min-height: 0;
    }
  }

  &__clock {
    display: block;
    position: absolute;
    bottom: 0;
    left: auto;
    right: 0;
    width: 88px;
    height: 52px;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .icon-clock(88px, 88px);
    }

    @media screen and (min-width: 768px) {
      width: 98px;
      height: 78px;
      left: 0;
      right: auto;
      &:before {
        width: 98px;
        height: 98px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .header {
    .transition(~"box-shadow 0s ease");
    overflow-y: visible;

    &--land {
      position: absolute !important;
    }

    &-inner {
      padding: 20px 0 23px;
      height: 62px;
    }

    &__helper {
      background: @cHeaderBG;
      position: relative;
      z-index: 1;

      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: @cHeaderBG;
        height: 22px;
      }
    }
  }

  .header-lang-item {
    position: relative;
  }

  .header-lang-block {
    position: absolute;
    left: 0;
    top: 30px;
  }
}

@media screen and (max-width: 1023px) {
  .header-menu {
    max-height: none !important;
  }

  .header-profile-block {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  body:not(._like-mobile) .header {
    overflow: visible;

    &__mm {
      display: none;
    }

    &__search {
      position: relative;
      .inline-block();
      right: auto;
      top: 1px;
      margin: 0 0 0 30px;
      opacity: 0;
      pointer-events: none;
      .transition(~"opacity .15s ease");

      &._vis {
        opacity: 1;
        pointer-events: all;
      }
    }

    &-main {
      position: relative;
    }
  }

  body:not(._like-mobile) .header-menu {
    display: none !important;
  }

  body:not(._like-mobile) .header-profile {
    display: block;
    .transition(~"top .15s ease");
  }

  body:not(._like-mobile) .menu {
    .inline-block();
    //display: none;
    margin: 1px 0 0 60px;
    @media screen and (min-width: 1024px) and (max-width: 1443px) {
      &-item:nth-child(n+5) {
        display: none;
      }

      &-item--more {
        display: inline-block !important;
      }
    }
  }

  body:not(._like-mobile) .gray-header .menu {
    .inline-block();
    pointer-events: all;
  }

  body:not(._like-mobile) .gray-header .header__search {
    opacity: 1;
    pointer-events: all;
  }
}

.lang-panel-radio {
  visibility: hidden;
  position: absolute;
}

.lang-panel-button {
  .inline-block(top);
  box-sizing: border-box;
  .fontHNRegular(13px, 28px);
  color: @cSearchFilterButtonText;
  border-top: @cSearchFilterButtonBord solid 1px;
  border-bottom: @cSearchFilterButtonBord solid 1px;
  border-left: @cSearchFilterButtonBord solid 1px;
  padding: 0 10px;
  text-decoration: none;
  .transition(~"color .15s ease, background .15s ease, border-color .15s ease");
  cursor: pointer;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 2px;
    margin-left: -1px;
    bottom: -1px;
    background: @cGreen;
    opacity: 0;
    .transition(~"opacity .15s ease");
  }

  label&:first-of-type {
    border-radius: 3px 0 0 3px;
    padding-right: 11px;
  }

  &:last-child {
    border-right: @cSearchFilterButtonBord solid 1px;
    border-radius: 0 3px 3px 0;

    &:before {
      display: none;
    }
  }
}

.lang-panel-radio:checked + .lang-panel-button {
  color: @cSearchFilterButtonActiveText;
  background: @cSearchFilterButtonActive;
  border-color: @cSearchFilterButtonActive;
  z-index: 2;

  &:before {
    opacity: 1;
  }
}

.lang-panel-radio:checked + .lang-panel-button + .lang-panel-radio + .lang-panel-button {
  border-left-color: @cSearchFilterButtonActive;
}

.gray-header {
  background: @cGrayHeaderBG;

  .header {
    &-lang {
      background: @cGrayHeaderBG;

      &__link {
        color: @cGrayHeaderColor;

        .no-touchevents &:hover {
          color: @cWhite;
        }

        &._active {
          color: @cWhite;
        }
      }
    }

    &__search {
      opacity: 1;
      pointer-events: all;

      &:before {
        .icon-searchBlack(100%, 100%);
      }
    }

    &__mm {
      border-color: @cGrayHeaderColor;

      &:before {
        background: @cGrayHeaderColor;
      }

      &._opened:before {
        background: transparent;
        .icon-close(20px, 20px);
      }
    }

    &-profile {
      &__link {
        color: @cGrayHeaderColor;
      }

      &__open {
        &:before {
          .icon-dropdownGray(11px, 6px);
        }
      }
    }
  }

  .menu {
    &__link {
      color: @cGrayHeaderColor;
    }

    @media screen and (min-width: 1024px) and (max-width: 1443px) {
      display: block;
      opacity: 1;
      pointer-events: all;
      &-item:nth-child(n+5) {
        display: none;
      }

      &-item--more {
        display: inline-block !important;
      }

      &__link--more {
        position: relative;
        padding: 11px 1px 10px;
        width: 19px;
        box-sizing: border-box;
        cursor: pointer;

        &:before {
          content: '';
          display: block;
          width: 17px;
          height: 3px;
          border-left: 3px solid @cGrayHeaderColor;
          border-right: 3px solid @cGrayHeaderColor;
          box-sizing: border-box;
          background: transparent;
          position: relative;
          bottom: 0;
          .transition(~"border-color .15s ease");
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 8px;
          top: 11px;
          width: 3px;
          height: 3px;
          background: @cGrayHeaderColor;
          .transition(~"background .15s ease");
        }

        .no-touchevents &:hover {
          &:before {
            border-color: @cGrayHeaderColorOver;
          }

          &:after {
            background: @cGrayHeaderColorOver;
          }
        }
      }

      .no-touchevents &-item--more:hover .menu__link--more {
        &:before {
          border-color: @cGrayHeaderColorOver;
        }

        &:after {
          background: @cGrayHeaderColorOver;
        }
      }
    }
    @media screen and (min-width: 1440px) {
      display: block;
      opacity: 1;
      &-item--more {
        display: none !important;
      }
    }
  }

  &.float-header {
    &._fixed {
      box-shadow: none;
      -webkit-backdrop-filter: blur(9px);
      backdrop-filter: blur(9px);
      background-color: @opGrayHeaderBlend;
    }
  }
}

.float-header._fixed + .page .header .header-main {
  opacity: 0;
}

.float-header._blend._fixed + .header .header-main {
  opacity: 0;
}
